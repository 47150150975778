<script lang="ts" setup>
const { currentDivision } = useDivisions()
</script>

<template>
  <div
    class="flex w-full"
    :class="{
      'pb-20 md:pb-0 print:pb-0': currentDivision,
    }"
  >
    <TheNavigation />
    <main
      class="mt-navigation pb-safe-bottom standalone:pt-safe-top mx-auto flex max-w-full flex-1 print:mt-0"
    >
      <slot class="" />
    </main>
  </div>
</template>
